<template>
  <Layout>
    <PageHeader :items="items" :title="title" />
    <div class="alertMsg">
      <b-alert v-model="showDismissibleAlert" dismissible variant="success">
        Meal Plan Added Successfully!
      </b-alert>
    </div>

    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <h5>Corporate : {{ corpData.corpBranchName }}</h5>
          <div class="row">
            <div class="table-responsive col-md-6">
              <table class="table table-striped mb-0" style="margin-top:15px;">
                <thead>
                  <tr>
                    <td>Meal Type</td>
                    <td>Minimum Guarantee Veg Count</td>
                    <td>Minimum Guarantee Non-veg Count</td>
                  </tr>
                </thead>
                <tbody v-for="data in vegNonvegCount" :key="data.id">
                  <tr>
                    <td>{{ data.mealPlanName }}</td>
                    <td>{{ data.vegCount }}</td>
                    <td>{{ data.nonVegCount }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-if="holidayArr.length > 0" class="col-md-5">
              <h6>Holiday List:</h6>
              <ul id="example-1">
                <li v-for="item in holidayArr" :key="item.holidayID">
                  {{ item.holidayTitle }} -
                  {{ item.holidayDate }}
                </li>
              </ul>
            </div>
          </div>
          <div class="mt-2">
            <h6>
              Start Date :
              <span style="font-weight: 400;">{{ corpData.startDate }}</span>
            </h6>
            <h6>
              End Date :
              <span style="font-weight: 400;">{{ corpData.endDate }}</span>
            </h6>
          </div>
        </div>
      </div>
    </div>

    <div
      v-for="(mainData, index1) in fullMealPlanData"
      :key="mainData.id"
      class="inner row"
    >
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="inner-repeater">
              <div class="inner">
                <h5 class="mb-2">{{ mainData.title }}</h5>

                <div
                  v-for="(item2, index2) in mainData.mealTypreArr.mealtypename"
                  :key="item2.id"
                  class="inner mb-3 row"
                >
                  <div class="col-md-3 row">
                    <h6 class="col mt-2" style="font-size: 16px;">
                      {{ item2.mealPlanName }}
                    </h6>
                    <input
                      :disabled="item2.isDisabled"
                      class="btn btn-themeOrange inner col-md-4"
                      type="button"
                      value="Add new"
                      @click="AddItem(index1, index2)"
                    />
                  </div>

                  <div
                    v-for="(item3, index3) in item2.mealList"
                    :key="item3.id"
                    class="row"
                  >
                    <!-- <div class="col-md-3">
                      <label class="form-label" >Select Menu : *</label>
                        <multiselect v-model="item3.dishID" :options="dishsArr" track-by="dishID" label="title" :show-labels="false"
                          @input="getDishPrice(index1,index2,index3,item3.dishID);" ></multiselect>

                    </div> -->

                    <div class="col-md-4">
                      <label class="form-label">Menu Title : *</label>
                      <input
                        v-model="item3.menuTitle"
                        :disabled="item3.isDisabled"
                        class="form-control"
                        type="text"
                      />
                    </div>
                    <div class="col-md-4">
                      <label class="form-label">Description *</label>
                      <textarea
                        v-model="item3.description"
                        :disabled="item3.isDisabled"
                        :maxlength="300"
                        class="form-control"
                        name="textarea"
                        rows="3"
                      ></textarea>
                    </div>
                    <div class="mb-3 col-md-4">
                      <label class="form-label" for="formrow-restName-input"
                        >Add Image</label
                      >
                      <div class="file-upload-form">
                        Choose File
                        <input
                          :disabled="item3.isDisabled"
                          accept="image/*"
                          class="uploadInput"
                          type="file"
                          @change="previewImage($event, index1, index2, index3)"
                        />
                      </div>

                      <div
                        v-if="item3.thumbnail && item3.thumbnail.length > 0"
                        :disabled="item3.isDisabled"
                        class="image-preview col-md-3"
                      >
                        <img :src="item3.thumbnail" class="preview" />
                      </div>
                    </div>

                    <div class="col-md-3">
                      <label class="form-label">Select Food Type : *</label>

                      <multiselect
                        v-model="item3.foodTypeID"
                        :disabled="item3.isDisabled"
                        :options="foodOptions"
                        :show-labels="false"
                        label="foodTypeName"
                        track-by="typeID"
                      ></multiselect>
                    </div>
                    <div class="col-md-2">
                      <label class="form-label">Price for Corporate</label>
                      <div class="input-group">
                        <div class="input-group-text">₹</div>
                        <input
                          v-model="item3.corpDiscountPrice"
                          :disabled="item3.isDisabled"
                          class="form-control"
                          type="number"
                        />
                      </div>
                    </div>

                    <div class="col-md-2">
                      <label class="form-label">Packing Charges</label>
                      <div class="input-group">
                        <div class="input-group-text">₹</div>
                        <input
                          v-model="item3.packingCharges"
                          :disabled="item3.isDisabled"
                          class="form-control"
                          type="number"
                        />
                      </div>
                    </div>

                    <div class="col-md-2">
                      <label class="form-label">Calories</label>
                      <div class="input-group">
                        <div class="input-group-text">Kcal</div>
                        <input
                          v-model="item3.calories"
                          :disabled="item3.isDisabled"
                          class="form-control"
                          type="number"
                        />
                      </div>
                    </div>

                    <div class="col-lg-1 align-self-center d-grid mt-3">
                      <input
                        class="btn btn-themeBrown btn-block inner"
                        type="button"
                        value="Delete"
                        @click="
                          deleteItem(
                            index1,
                            index2,
                            index3,
                            item3.configuredPlanID
                          )
                        "
                      />
                    </div>
                    <hr
                      style="border-top: 2px solid #bfbfbf;margin-top: 24px;"
                    />
                  </div>
                  <!-- <div class="col-lg-1 align-self-center d-grid mt-3">
                          <input
                            type="button"
                            class="btn btn-themeOrange inner"
                            value="Add new item"
                            @click="AddItem(index1,index2)"
                          />
                  </div> -->
                  <hr
                    style="border-top: 2px dashed #bfbfbf;margin-top: 24px;"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="row"
      style="bottom: 22px;
    right: 39px;
    position: fixed;"
    >
      <b-spinner
        v-if="loading"
        class="m-2 col-3"
        role="status"
        variant="primary"
      ></b-spinner>
      <button
        :disabled="loading"
        class="col-3 btn btn-themeYellow w-md waves-effect waves-light"
        type="button"
        @click="submitForm()"
      >
        Submit
      </button>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import { authHeader } from "../../../helpers/fakebackend/auth-header";

/**
 * Advanced-form component
 */
export default {
  page: {
    title: "Configure Meal Plan",
    meta: [
      {
        name: "description",
        content: appConfig.description
      }
    ]
  },
  components: {
    Multiselect,
    Layout,
    PageHeader
  },
  data() {
    return {
      title: "Configure Meal Plan",
      items: [
        {
          text: "Configure Meal Plan",
          href: "/"
        },
        {
          text: "Add",
          active: true
        }
      ],

      status: "",
      submitted: false,
      showDismissibleAlert: false,
      loading: false,
      restaurants: [],
      corpData: [],
      isEdit: 0,
      vegNonvegCount: [],
      holidayArr: [],
      dishsArr: [],
      mealTypeNameArr: [],
      fullMealPlanData: [],
      foodOptions: []
    };
  },

  //   validations: {
  //   posts: {
  //     categoryName: {
  //       required,
  //     },
  //     // stepNO: {
  //     //   required,
  //     // },
  //     // restaurantID:{
  //     //   required,
  //     // }
  //   },
  // },
  // computed:{
  //     disabledFields(index){
  //     if(index==5 || index==6){
  //       alert();
  //       return true;
  //     }else{
  //       return false;
  //     }
  //   },
  // },
  methods: {
    submitForm() {
      this.submitted = true;

      this.loading = true;
      let formData = new FormData();

      formData.append(
        "corpMealPlanID",
        sessionStorage.getItem("corpMealPlanID")
      );
      formData.append("mealPlanArr", JSON.stringify(this.fullMealPlanData));
      formData.append("createdBy", this.$storageData.profile.pid);
      formData.append("loginTypeID", this.$storageData.login_type);

      this.axios
        .post(this.$loggedRole+"/addConfigureMealPlanData", formData, {
          headers: authHeader()
        })
        .then(result => {
          this.status = result.data.data;
          this.showDismissibleAlert = true;
          this.loading = false;

          setTimeout(() => {
            this.$router.push({ name: "corporateMealPlanTable" });
          }, 2000);

          // console.warn(result);
        })
        .catch(error => {
          this.loading = false;
          alert(error.response.data.message);
        });
      //}
    },

    getRestaurantList() {
      this.axios
        .post(this.$loggedRole+"/getRestaurantList", {
          restID: this.$storageData.profile.restID,
          restBranchID: this.$storageData.profile.restBranchID,
          empTypeID: this.$storageData.profile.empTypeID
        })
        .then(result => {
          this.restaurants = result.data.data;
        });
    },
    getCorporateMealPlanByID() {
      this.axios
        .post(this.$loggedRole+"/getCorporateMealPlanByID", {
          corpMealPlanID: sessionStorage.getItem("corpMealPlanID")
        })
        .then(result => {
          this.corpData = result.data.data;
          this.isEdit = result.data.isEdit;
          this.vegNonvegCount = result.data.vegNonvegCount;
          this.holidayArr = result.data.holidayArr;
          this.mealTypeNameArr = result.data.mealTypeNameArr;
          //  this.dishsArr = result.data.dishsArr;
          this.fullMealPlanData = result.data.mealPlanArr;
          //console.log(this.mondayArr);
        });
    },

    AddItem(index1, index2) {
      this.fullMealPlanData[index1].mealTypreArr.mealtypename[
        index2
      ].mealList.push({
        configuredPlanID: 0,
        packingCharges: "",
        foodTypeID: "",
        corpDiscountPrice: "",
        menuTitle: "",
        description: "",
        image: "",
        thumbnail: "",
        calories: ""
      });
    },

    deleteItem(index1, index2, index3, configuredPlanID) {
      if (configuredPlanID > 0) {
        if (confirm("Are you sure you want to delete this element?")) {
          this.fullMealPlanData[index1].mealTypreArr.mealtypename[
            index2
          ].mealList.splice(index3, 1);
          this.axios
            .post(this.$loggedRole+"/deleteConfiguredPlanByID", {
              configuredPlanID: configuredPlanID
            })
            .then(() => {});
        }
      } else {
        this.fullMealPlanData[index1].mealTypreArr.mealtypename[
          index2
        ].mealList.splice(index3, 1);
      }
    },
    // getDishPrice(index1,index2,index3,dishID){

    //     this.axios.post(this.$loggedRole+"/getDishPriceByID",
    //     {'dishID':dishID.dishID})
    //     .then((result)=>{
    //             this.dishPrice = result.data.data.price;
    //              this.fullMealPlanData[index1].mealTypreArr.mealtypename[index2].mealList[index3].price = this.dishPrice;
    //              this.fullMealPlanData[index1].mealTypreArr.mealtypename[index2].mealList[index3].corpDiscountPrice = this.dishPrice;
    //     });
    // },

    previewImage(event, index1, index2, index3) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();

        // reader.onload = (e) => {
        //   //this.fullMealPlanData[index1].mealTypreArr.mealtypename[index2].mealList[index3].imageData = e.target.result;
        // }

        reader.readAsDataURL(input.files[0]);

        let formData = new FormData();

        formData.append("document", input.files[0]);
        let headers = authHeader();
        headers["Content-Type"] = "multipart/form-data";
        this.axios
          .post(this.$loggedRole+"/imageUploadToBucket", formData, {
            headers
          })
          .then(result => {
            this.fullMealPlanData[index1].mealTypreArr.mealtypename[
              index2
            ].mealList[index3].image = result.data.data.urlOg;
            this.fullMealPlanData[index1].mealTypreArr.mealtypename[
              index2
            ].mealList[index3].thumbnail = result.data.data.urlThumb;
          });
      }
    },
    getFoodTypeList() {
      this.axios.get(this.$loggedRole+"/getFoodTypeList").then(result => {
        this.foodOptions = result.data.data;
      });
    }
  },
  mounted() {
    //alert();
    // this.getRestaurantList();
    this.getFoodTypeList();
    if (this.$route.params.id >= 0) {
      sessionStorage.setItem("corpMealPlanID", this.$route.params.id);
    }
    this.getCorporateMealPlanByID();
  },

  middleware: "authentication"
};
</script>
<style>
.alertMsg {
  position: fixed;
  right: 0px;
  z-index: 500;
  top: 79px;
  width: 22%;
}

img.preview {
  width: 100px;
  height: 100px;
  background-color: white;
  border: 1px solid #ddd;
  padding: 5px;
  object-fit: contain;
  margin-top: 6px;
}
</style>
